<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterList">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.title"
                id="filter_title"
                :label="$t('hotel.filter.title')"
              ></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.name"
                id="filter_brand"
                :label="$t('hotel.filter.name')"
              ></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.id"
                :label="$t('hotel.filter.id')"
              ></app-input>
            </div>
            <div class="col-lg-2">
              <button
                type="button"
                class="btn btn-success pull-right m-t-30"
                data-test="search"
                @click="filterList"
              >
                Search
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import HotelFilterModel from '../../model/HotelFilterModel'

export default {
  name: 'HotelFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(HotelFilterModel)
    }
  },
  components: {
    appInput: Input
  },
  methods: {
    filterList () {
      this.$store.commit('hotel/setPage', 1)
      this.$store.commit('hotel/setFilter', this.filter)
      this.$store.dispatch('hotel/fetch')
    },
    resetFilter () {
      this.$store.commit('hotel/setPage', 1)
      this.$store.commit('hotel/resetFilter')
      this.$store.dispatch('hotel/fetch')
    }
  },
  created () {
    this.filter = this.$store.getters['hotel/filter']
  }
}
</script>
