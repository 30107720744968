<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible"
        route-name="hotel_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-filter></app-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="list"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :hasEditPermission="isEditButtonVisible"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import { mapState } from 'vuex'
import ModuleView from '../../components/ModuleView'
import Preloader from '../../components/preloader/Preloader'
import ButtonCreate from '../../components/shared/ButtonCreate'
import DataTable from '../../components/shared/DataTable'
import Filter from '../../components/filter/FilterHotel'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'HotelListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('hotel.list.id'),
          title: this.$t('hotel.list.title'),
          name: this.$t('hotel.list.name')
        },
        actions: {
          copyToClipboard: 'id',
          detail: 'hotel_detail',
          edit: 'hotel_edit'
        },
        requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ARTICLE_TEST_HOTEL_PERMISSIONS
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    list () {
      return this.$store.getters['hotel/list']
    },
    totalCount () {
      return this.$store.getters['hotel/totalCount']
    },
    page () {
      return this.$store.getters['hotel/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFilter: Filter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getList () {
      this.$store.dispatch('hotel/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('hotel/setPage', page)
      this.getList()
    }
  },
  mounted () {
    this.getList()
  }
}
</script>
